<template>
    <div class="main-menu">
        <div class="menu-container">
            <ul class="nav nav-pills flex-column ">
                <li class="nav-item">
                    <a class="nav-link {'active': isActive('/dashboard')}" href="/dashboard">Home
                        <svg xmlns='http://www.w3.org/2000/svg' class="icon-size-16 arrow" viewbox='0 0 512 512'>
                            <title>ionicons-v5-a</title>
                            <polyline points='184 112 328 256 184 400' style='fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px'></polyline>
                        </svg>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link {'active': isActive('/presence-list')}" href="/presence">Presence
                        <svg xmlns='http://www.w3.org/2000/svg' class="icon-size-16 arrow" viewbox='0 0 512 512'>
                            <title>ionicons-v5-a</title>
                            <polyline points='184 112 328 256 184 400' style='fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px'></polyline>
                        </svg>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link {'active': isActive('/presence-list')}" href="/presence-list">Presence List
                        <svg xmlns='http://www.w3.org/2000/svg' class="icon-size-16 arrow" viewbox='0 0 512 512'>
                            <title>ionicons-v5-a</title>
                            <polyline points='184 112 328 256 184 400' style='fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px'></polyline>
                        </svg>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link {'active': isActive('/take-home-pay')}" href="/take-home-pay">Take Home Pay
                        <svg xmlns='http://www.w3.org/2000/svg' class="icon-size-16 arrow" viewbox='0 0 512 512'>
                            <title>ionicons-v5-a</title>
                            <polyline points='184 112 328 256 184 400' style='fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px'></polyline>
                        </svg>
                    </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link {'active': isActive('/profile')}" href="/profile">Profile
                        <svg xmlns='http://www.w3.org/2000/svg' class="icon-size-16 arrow" viewbox='0 0 512 512'>
                            <title>ionicons-v5-a</title>
                            <polyline points='184 112 328 256 184 400' style='fill:none;stroke-linecap:round;stroke-linejoin:round;stroke-width:48px'></polyline>
                        </svg>
                    </a>
                </li>
            </ul>
            <a @click="handleLogout" href="#" class="text-danger my-3 d-block">Logout</a>
            <button class="btn btn-danger sqaure-btn close text-white">
                <img src="/img/icons/icon-x.svg" alt="" class="icon-size-24">
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "MenuLayout",
    props: {
        name: {
            type: String,
            required: true,
        },
    },
    methods: {
        handleLogout() {
            // Contoh: Menghapus token atau data login dari localStorage
            localStorage.removeItem('status');
            
            // Contoh: Redirect ke halaman login
            this.$router.push('/');
        },
        isActive(route) {
            // Cek apakah rute saat ini cocok
            return this.$route.path === route;
        }
    }

};
</script>