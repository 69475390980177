<template>
  <MenuLayout />
  
  <main class="flex-shrink-0">
    <HeaderLayout :name="name" />

    <div class="container mt-4">
      <div class="row">
        <div class="col-12 col-md-12">
          <div class="form-group floating-form-group">
            <select class="form-control floating-input" name="koordinat" v-model="selectedCoordinate">
              <option v-for="coordinate in coordinates" :key="coordinate.id" :value="`${coordinate.id}|${coordinate.koordinat}`">
                {{ coordinate.name }}
              </option>
            </select>
          </div>
          <div class="form-group floating-form-group">
            <input class="form-control floating-input" type="datetime-local" v-model="currentDateTime" name="datetime" id="datetime">
          </div>
        </div>
      </div>
      <div class="row my-4">
        <div class="col-12 col-md-6 col-lg-4 mx-auto">
          <button 
            type="button" 
            class="btn btn-block btn-danger btn-lg" 
            @click="submitPresence" 
            :disabled="loading"
          >
            <span v-if="loading">Processing...</span>
            <span v-else>Presence</span>
          </button>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import MenuLayout from "../components/MenuLayout.vue";
import HeaderLayout from "../components/HeaderLayout.vue";
import { config } from '../config.js';
import { format, toZonedTime } from "date-fns-tz";

export default {
  name: "UserPresence",
  components: { MenuLayout, HeaderLayout },
  data() {
    return {
      userInfo: {},
      name: 'UserPresence',
      currentDateTime: '',
      coordinates: [],
      selectedCoordinate: "",
      loading: false, // State to track loading status
    };
  },
  async created() {
    console.log('Component created'); // Debugging log for component creation

    try {
      const status = localStorage.getItem('status');
      const username = localStorage.getItem('username');
      console.log("Status:", status); // Log status
      console.log("Username:", username); // Log username

      if (status !== 'valid') {
        this.$router.push('/');
        console.log("Redirecting to login page..."); // Log pengalihan
      }

      const now = new Date();
      const timeZone = "Asia/Jakarta";
      const zonedTime = toZonedTime(now, timeZone);
      this.currentDateTime = format(zonedTime, "yyyy-MM-dd'T'HH:mm");

      const response = await fetch(config.apiUrl + '/api/coordinate', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username })
      });

      if (response.ok) {
        const data = await response.json();
        this.coordinates = data.results;
        if (this.coordinates.length > 0) {
          this.selectedCoordinate = `${this.coordinates[0].id}|${this.coordinates[0].koordinat}`;
        }
      } else {
        console.error("Failed to fetch coordinates");
      }
    } catch (err) {
      console.error("Error in created:", err);
      this.$router.push('/');
    }
  },
  methods: {
    async submitPresence() {
      this.loading = true; // Set loading to true when the function is called
      console.log("submitPresence clicked!"); // Check if function is triggered
      try {
        if (!this.selectedCoordinate || !this.currentDateTime) {
          alert("Please select a coordinate and datetime.");
          this.loading = false; // Reset loading if inputs are invalid
          return;
        }

        const [coordinate_id, coordinate] = this.selectedCoordinate.split("|");
        const username = localStorage.getItem('username');

        const payload = {
          username: username,
          coordinate_id: coordinate_id,
          coordinate: coordinate,
          date_time: this.currentDateTime,
        };

        console.log("Sending payload:", payload); // Debug payload

        const response = await fetch(config.apiUrl + '/api/presence', {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        });

        if (response.ok) {
          const result = await response.json();
          alert("Presence submitted successfully: " + JSON.stringify(result));
          window.location.href = '/presence-list';
        } else {
          const error = await response.json();
          alert("Failed to submit presence: " + error.message);
        }
      } catch (error) {
        console.error("Error submitting presence:", error);
        alert("An error occurred. Please try again.");
      } finally {
        this.loading = false; // Reset loading after the request is finished
      }
    },
  },
};
</script>

<style scoped>
/* Optional: Style for the loader text */
.btn[disabled] {
  pointer-events: none;
  background-color: #ccc;
}
</style>